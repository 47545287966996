import React from 'react';
import { FormProvider } from '@xtreamsrl/react-forms';
import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Tabs } from '@xtreamsrl/react-ui-kit/Tabs';
import FullScreenCard from 'src/requests/components/FullScreenCard';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { CassettoApiIcon } from '@cassetto/icons';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import Callout from '../../../requests/components/Callout/Callout';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import RequestDataRetriever from '../../../requests/views/DataRequest';
import {
  RequestContext,
  useDemoRequestCreator,
} from '../../hooks/useDemoRequestCreator';
import { Ribbon } from '../../components/Ribbon';

const DemoRequestCreator: React.FC = () => {
  const {
    activeStep,
    back,
    currentTab,
    setCurrentTab,
    requestId,
    isPending,
    isError,
    tabs,
    requestCreatorForm,
    emailForm,
    handleEmailSubmit,
    submit,
  } = useDemoRequestCreator();

  if (requestId) {
    return (
      <RequestContext.Provider value={requestId}>
        <Ribbon text="DEMO" />
        <RequestDataRetriever />
      </RequestContext.Provider>
    );
  }

  return (
    <>
      <Ribbon text="DEMO" />
      <FullScreenCard>
        <Flex direction="column" gap="md-2" alignItems="center" width="100%">
          <CassettoApiIcon />

          {
            {
              0: (
                <FormProvider enableDevTools={false} {...emailForm.formProps}>
                  <form
                    style={{ width: '100%' }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEmailSubmit().catch((error) => {
                        console.error('Failed to submit form:', error);
                      });
                    }}
                  >
                    <Flex direction="column" gap="sm-4">
                      <Typography variant="body/xl/semibold">
                        Prendi decisioni migliori con i dati fiscali dei tuoi
                        clienti.
                      </Typography>
                      <Typography variant="body/base/regular">
                        Scopri quanto è facile e veloce recuperare un ampio set
                        documentale dai tuoi utenti, lasciandoli autenticare
                        tramite <strong>SPID</strong> o <strong>CIE</strong>. In
                        ambiente reale (non demo) i dati verrebbero poi
                        elaborati ed inviati all'istituto bancario o alla
                        fintech che sta offrendo la soluzione tramite
                        CassettoAPI.
                        <br />
                        Inserisci il tuo indirizzo e-mail per avviare la
                        dimostrazione.
                      </Typography>
                      <FormTextInput
                        label="E-mail"
                        name="email"
                        placeholder="example@cassettoapi.it"
                      />
                      <Callout
                        status="info"
                        text="Informazioni sull'utilizzo dei dati"
                      >
                        <Typography variant="body/sm/regular">
                          I documenti scaricati saranno eliminati immediatamente
                          al termine della sessione e non utilizzati o
                          memorizzati in alcun modo. Proseguendo, acconsenti
                          all'utilizzo dei dati esclusivamente a fini
                          dimostrativi.
                        </Typography>
                      </Callout>
                      <Box width="100%" textAlign="right">
                        <Button type="submit">Avanti</Button>
                      </Box>
                    </Flex>
                  </form>
                </FormProvider>
              ),
              1: (
                <FormProvider
                  enableDevTools={false}
                  {...requestCreatorForm.formProps}
                >
                  <form
                    style={{ width: '100%' }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      submit().catch((error) => {
                        console.error('Failed to submit form:', error);
                      });
                    }}
                  >
                    <Tabs
                      currentTab={currentTab}
                      fullWidth
                      onChange={(_, t) => {
                        setCurrentTab(t);
                        requestCreatorForm.formProps.setValue(
                          'target',
                          tabs[t].code,
                        );
                      }}
                    >
                      <Tabs.Tab value={0}>{tabs[0].name}</Tabs.Tab>
                      <Tabs.Tab value={1}>{tabs[1].name}</Tabs.Tab>
                    </Tabs>
                    {Object.keys(tabs).map((key) => {
                      const index = Number(key);
                      return (
                        <Tabs.Panel
                          index={index}
                          currentTab={currentTab}
                          key={index}
                        >
                          <Flex direction="column" marginTop="md-1">
                            {tabs[index].code === 'PF' ? (
                              <FormTextInput
                                autoComplete="on"
                                label="Codice fiscale"
                                name="fiscalCode"
                              />
                            ) : (
                              <>
                                <FormTextInput
                                  autoComplete="on"
                                  label="Partita IVA"
                                  name="vatNumber"
                                />
                                <FormTextInput
                                  label="Nome Azienda"
                                  name="name"
                                />
                              </>
                            )}
                            {isError && (
                              <Box marginTop="sm-2" marginBottom="md-1">
                                <Callout status="error" text="Errore">
                                  <Typography variant="body/sm/regular">
                                    Si è verificato un errore durante la
                                    creazione della richiesta. Riprova...
                                  </Typography>
                                </Callout>
                              </Box>
                            )}
                            <Flex justifyContent="space-between">
                              <Button
                                variant="outlined"
                                color="grey"
                                onClick={back}
                              >
                                Indietro
                              </Button>
                              <Button loading={isPending} type="submit">
                                Avvia Demo
                              </Button>
                            </Flex>
                          </Flex>
                        </Tabs.Panel>
                      );
                    })}
                  </form>
                </FormProvider>
              ),
            }[activeStep]
          }
        </Flex>
      </FullScreenCard>
    </>
  );
};

DemoRequestCreator.displayName = 'DemoRequestCreator';
export default DemoRequestCreator;
