import { createTheme } from '@xtreamsrl/react-ui-kit/theme';
import { getPaletteFromCompanyColor } from './_shared/utils/getPaletteFromCompanyColor';

export function createCustomTheme(brandColor = '#0066CC') {
  const generatedTheme = getPaletteFromCompanyColor({
    appearance: 'light',
    accent: brandColor,
    gray: '#889096',
    background: '#FFFFFF',
  });
  return createTheme({
    typography: {
      fontFamily: 'Inter',
    },
    palette: {
      background: {
        default: generatedTheme.background,
      },
      brand: {
        '1': generatedTheme.accentScale[0],
        '2': generatedTheme.accentScale[1],
        '3': generatedTheme.accentScale[2],
        '4': generatedTheme.accentScale[3],
        '5': generatedTheme.accentScale[4],
        '6': generatedTheme.accentScale[5],
        '7': generatedTheme.accentScale[6],
        '8': generatedTheme.accentScale[7],
        '9': generatedTheme.accentScale[8],
        '10': generatedTheme.accentScale[9],
        '11': generatedTheme.accentScale[10],
        '12': generatedTheme.accentScale[11],
      },
      info: {
        '1': '#FCFDFE',
        '2': '#F6FAFF',
        '3': '#EAF3FE',
        '4': '#DCEBFF',
        '5': '#CAE2FF',
        '6': '#B6D5FE',
        '7': '#9DC3F6',
        '8': '#78ABEE',
        '9': '#0066CC',
        '10': '#0057BC',
        '11': '#0769CF',
        '12': '#0E325E',
      },
      grey: {
        '1': generatedTheme.grayScale[0],
        '2': generatedTheme.grayScale[1],
        '3': generatedTheme.grayScale[2],
        '4': generatedTheme.grayScale[3],
        '5': generatedTheme.grayScale[4],
        '6': generatedTheme.grayScale[5],
        '7': generatedTheme.grayScale[6],
        '8': generatedTheme.grayScale[7],
        '9': generatedTheme.grayScale[8],
        '10': generatedTheme.grayScale[9],
        '11': generatedTheme.grayScale[10],
        '12': generatedTheme.grayScale[11],
      },
    },
  });
}
