import { useForm } from '@xtreamsrl/react-forms';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useCreateDemoRequest } from '../mutations/createDemoRequest';

export type TargetType = 'PF' | 'PG';
const fiscalCodeRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
const vatNumberRegex = /^[0-9]{11}$/;
const mailValidationSchema = yup.object().shape({
  email: yup.string().email('errors.email').required('errors.required'),
});

const validationSchema = yup.object().shape({
  target: yup
    .mixed<TargetType>()
    .oneOf(['PF', 'PG'])
    .required('errors.required'),
  fiscalCode: yup.string().when('target', {
    is: 'PF',
    then: () =>
      yup
        .string()
        .matches(fiscalCodeRegex, 'errors.fiscalCode')
        .required('errors.required'),
    otherwise: () =>
      yup
        .string()
        .optional()
        .transform(() => undefined),
  }),
  vatNumber: yup.string().when('target', {
    is: 'PG',
    then: () =>
      yup
        .string()
        .matches(vatNumberRegex, 'errors.vatNumber')
        .required('errors.required'),
    otherwise: () =>
      yup
        .string()
        .optional()
        .transform(() => undefined),
  }),
  name: yup.string().when('target', {
    is: 'PG',
    then: () => yup.string().required('errors.required'),
    otherwise: () =>
      yup
        .string()
        .optional()
        .transform(() => undefined),
  }),
});
export const RequestContext = React.createContext<string | undefined>(
  undefined,
);

export function useDemoRequestCreator() {
  const [email, setEmail] = useState<string>('');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [requestId, setRequestId] = useState<string>();
  const { mutate, isPending, isError } = useCreateDemoRequest({
    onSuccess: (data) => {
      setRequestId(data);
    },
  });
  const tabs: Record<number, { code: TargetType; name: string }> = {
    0: {
      code: 'PF',
      name: 'Persona fisica',
    },
    1: {
      code: 'PG',
      name: 'Persona legale',
    },
  };

  const emailForm = useForm({
    initialValues: {
      email: email,
    },
    validationSchema: mailValidationSchema,
    mode: 'onBlur',
  });

  const requestCreatorForm = useForm({
    initialValues: {
      target: 'PF' as TargetType,
      name: '',
      fiscalCode: '',
      vatNumber: '',
    },
    validationSchema: validationSchema,
    mode: 'onBlur',
  });

  const submit = requestCreatorForm.formProps.handleSubmit((values, event) => {
    event?.preventDefault();
    mutate({ email, ...values });
  });

  const handleEmailSubmit = emailForm.formProps.handleSubmit(
    (values, event) => {
      event?.preventDefault();
      setEmail(values.email);
      setActiveStep(1);
    },
  );

  const back = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  return {
    activeStep,
    back,
    currentTab,
    setCurrentTab,
    requestId,
    isPending,
    isError,
    tabs,
    requestCreatorForm,
    emailForm,
    submit,
    handleEmailSubmit,
  };
}
